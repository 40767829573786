import React from "react"

const ExtLink = ({ url, linkText, title }) => {
	if ( url === '' ) {
		return null;
	}

	return (
		<div className="preview__meta-row">
			<h3>{ ( title !== '' ? title + ':\u00A0' : '' ) }</h3>
			<a 	className="btn btn--focus-hvr"
				href={url}
				target="_blank"
				rel="noopener noreferrer"
			>
				{linkText}
			</a>
		</div>
	)

}

export default ExtLink
