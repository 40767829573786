import React from "react"
import ProjectRow from "./projectRow"

const ProjectRows = ({ rows }) => {
	if ( !rows || rows.length < 1 ) {
		return null;
	}
	return (
		rows.map( (row, index) => (
			<ProjectRow 
				data={row}
				key={index}
			/>
		))
	)
}

export default ProjectRows;