import React from "react"
import { Link } from "gatsby"

const CatLinks = ({ linkData, title, prefix }) => {
	if ( linkData.length < 1 ) {
		return null;
	}

	return (
		<div className="preview__meta-row">
			<h3>{title + ':'}&nbsp;</h3>
			{ linkData.map( (link, index) => (
				<span>
					{ (index ? ', ' : '') }
					<Link 
						key={ link.id + index }
						className="btn btn--focus-hvr"
						to={`/${prefix + '/' + link.uri}`}
						dangerouslySetInnerHTML={{ __html: link.name }}
					>
					</Link>
				</span>
			)
			) }
		</div>
	)
}

export default CatLinks
