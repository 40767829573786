import React from "react"

const ProjectRowImage = ({ image }) => {
	if ( !image ) {
		return null;
	}
	
	return (
		<div className="preview__col preview__main">
			<div className="preview__featured-img">
				<img src={image.sourceUrl} srcSet={image.srcSet} alt={image.altText} />
			</div>
		</div>
	)
}

export default ProjectRowImage;