import React from "react"
import ProjectRowImage from "./projectRowImage"

const ProjectRow = ({ data }) => {
	const className = ( data.orientation === 'right' ) ? ' is-reversed' : '';

	return (
		<div className={`preview__grid` + className }>
			<div className="preview__col preview__aside" dangerouslySetInnerHTML={{ __html: data.text }}>
			</div>
			<ProjectRowImage image={data.image} />
		</div>
	)
}

export default ProjectRow;