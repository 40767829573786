import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"
import Breadcrumbs from "../components/links/breadcrumbs"
import ProjectMeta from "../components/single/projectMeta"
import ProjectMain from "../components/single/projectMain"
import ProjectRows from "../components/single/projectRows"

const Project = props => {
	const {
		data: {
			wpgraphql: { project }
		},
	} = props;
	const { title } = project;
	const mainImage = ( project.acfWork.primaryImage ) ? project.acfWork.primaryImage : project.featuredImage;
	return (
		<Layout contentType={`post`}>
			<div id="article-content" className="entry-content"> 
				<PageBanner title={title} />
				<section className="preview preview--single light">
					<Breadcrumbs title={`Portfolio`} url={`/portfolio`} />
					<div className="constrained">
						<div className="preview__single">
							<div className="preview__grid">
								<ProjectMeta project={project} />
								<ProjectMain image={mainImage} />
							</div>
							<ProjectRows rows={project.acfWork.projectRows} />
						</div>
					</div>
					<Breadcrumbs title={`Portfolio`} url={`/portfolio`} />
				</section>
			</div>
		</Layout>
	)
}

export default Project;

export const pageQuery = graphql`
	query GET_PROJECT($id: ID!) {
		wpgraphql {
			project(id: $id) {
				title(format: RENDERED)
				content(format: RENDERED)
				projectCategories {
					nodes {
						id
						name
						link
						uri
					}
				}
				industries {
					nodes {
						id
						name
						link
						uri
					}
				}
				acfWork {
					designer {
						title
						url
					}
					projectUrl {
						title
						url
					}
					primaryImage {
						srcSet(size: FEATURED)
						sourceUrl(size: FEATURED)
						altText
					}
					projectRows {
						orientation
						text
						image {
							srcSet(size: FEATURED)
							sourceUrl(size: FEATURED)
							altText
						}
					}
				}
				featuredImage {
					node {
						srcSet(size: FEATURED)
						sourceUrl(size: FEATURED)
						altText
					}
				}
			}
		}
	}
`