import React from "react"

const ProjectMain = ({ image }) => {
	if ( !image ) {
		return null;
	}

	const { srcSet, srcUrl, alt } = image
	return (
		<div className="preview__col preview__main">
			<div className="preview__featured-img">
				<img src={srcUrl} srcSet={srcSet} alt={alt} />
			</div>
		</div>
	)
}

export default ProjectMain;