import React from "react"
import CatLinks from "../links/catLinks"
import ExtLink from "../links/extLink"

const ProjectMeta = ({ project }) => {
	const { content, projectCategories, industries, acfWork } = project;
	const designerUrl = ( acfWork.designer && acfWork.designer.url ) ? acfWork.designer.url : '';
	const designerTitle = ( acfWork.designer && acfWork.designer.title ) ? acfWork.designer.title : '';
	const projectUrl = ( acfWork.projectUrl && acfWork.projectUrl.url ) ? acfWork.projectUrl.url : '';

	return (
		<div className="preview__col preview__aside">
			<div className="preview__meta">
				<h2>Project Details</h2>
				<CatLinks
					linkData={industries.nodes} 
					title='Industry'
					prefix='portfolio/'
				/>
				<CatLinks
					linkData={projectCategories.nodes} 
					title='Categories'
					prefix='portfolio/'
				/>
				<ExtLink
					url={designerUrl}
					linkText={designerTitle}
					title='Designer'
				/>
				<ExtLink
					url={projectUrl}
					linkText='View Website'
					title=''
				/>
				<div dangerouslySetInnerHTML={{ __html: content }} >
				</div>
			</div>
		</div>
	)
}

export default ProjectMeta;